import { Card } from "components/anti"
import Cookies from "js-cookie"
import { ENUM } from "lib/utils/enum"
import { FormatCurrency } from "lib/utils/general-utils"
import { useEffect, useState } from "react"

import holdersOnlyLogo from "assets/img/common/logo-holders-only.png"
import personalizedLogo from "assets/img/common/logo-personalized.png"
import { dataLayerEvent } from "lib/functions/datalayer-v2"
import { useInView } from "react-intersection-observer"
import { Q_BRAND_COLLECTION } from "lib/graphql"
import { useRouter } from "next/router"
import { AsyncApollo } from "lib/functions"

import imgPreorder from "assets/img/common/logo-preorder.png"
import imgOfflineOnly from "assets/img/common/logo-offline-only.png"

const CardProduct = ({
  product,
  className,
  key,
  listName,
  position,
  listId,
}) => {
  //Utils ************************************************************************************************** //
  const Router = useRouter()

  const isOnSale = product?.sell_price < product?.price
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 1,
  })
  const [details, setDetails] = useState([])
  //Utils ************************************************************************************************** //

  //States ************************************************************************************************* //
  const [state, setState] = useState({ link_product: "/" })
  //States ************************************************************************************************* //

  //Functions ********************************************************************************************** //
  const handleParseSlug = () => {
    if (product) {
      let product_link = "/product"
      let { slug, category, firstcategory, secondcategory, thirdcategory, id } =
        product
      if (category) product_link += `/${category.slug}`
      else if (firstcategory) product_link += `/${firstcategory?.slug}`
      if (secondcategory) product_link += `/${secondcategory?.slug}`
      if (thirdcategory) product_link += `/${thirdcategory?.slug}`
      if (slug) product_link += `/${slug}/${id}`
      return product_link
    }
  }
  const handleGetDetails = async () => {
    let { data, errors } = await AsyncApollo({
      query: Q_BRAND_COLLECTION,
      variables: {
        lang: Router.locale,
        id: product?.id,
        currency: Cookies.get(process.env.CURRENCY),
      },
    })
    if (data?.product) {
      setDetails(data?.product)
    }
  }

  //Functions ********************************************************************************************** //

  //React Operations *************************************************************************************** //
  useEffect(() => {
    handleGetDetails()
  }, [])
  useEffect(() => {
    setState((prev) => ({ ...prev, link_product: handleParseSlug() }))
  }, [product])
  useEffect(() => {
    if (inView) {
      // DataLayer
      // productView({
      //   path: Router.asPath,
      //   productName: product?.title,
      //   userId: user_data?.id,
      //   currencyCode: Cookies.get(process.env.CURRENCY),
      //   products: [{ ...product }],
      //   position,
      //   listName,
      // })
      dataLayerEvent.viewItemList({
        ...product,
        listName,
        listId,
        position,
        brand: { ...details.brand },
        collection: { ...details.collection },
        currency: Cookies.get(process.env.CURRENCY),
        // brand: {...prod}
      })
    }
  }, [inView, product])
  //React Operations *************************************************************************************** //
  return (
    <div ref={ref}>
      <Card
        variant="boxless"
        img={product.images[0]}
        // link={`${state.link_product}?listName=${listName}`}
        link={{
          pathname: "/product/[...slug]",
          query: { listName, position },
        }}
        as={state?.link_product}
        imgHover={product?.images[1]}
        className={`card-product ${className ?? ""}`}
        imgRatio={ENUM.IMAGE_RATIO}
        product={product}
        key={key}
        useImgLoading
        onClick={() => {
          // DataLayer
          dataLayerEvent.selectItem({
            ...product,
            listId,
            listName,
            position,
            currency: Cookies.get(process.env.CURRENCY),
          })
        }}
        imgLogoBottomRight={
          +product?.nft_id
            ? personalizedLogo
            : product?.for_holder
            ? holdersOnlyLogo
            : null
        }
        imgLogoTopRight={
          product?.venue_only
            ? imgOfflineOnly
            : product.manage_stock === 0
            ? imgPreorder
            : null
        }
      >
        <h6 className="product-title">{product.title}</h6>
        {product?.firstcategory?.slug !== "coca-cola-merchandise" && (
          <p className={`product-price ${isOnSale ? "is-on-sale" : ""}`}>
            <span>
              {FormatCurrency(
                isOnSale ? product?.price : product?.sell_price,
                Cookies.get(process.env.CURRENCY)
              )}
            </span>
            {isOnSale ? (
              <span className="sale-price">
                {FormatCurrency(
                  product?.sell_price,
                  Cookies.get(process.env.CURRENCY)
                )}
              </span>
            ) : null}
          </p>
        )}
      </Card>
    </div>
  )
}

export default CardProduct
